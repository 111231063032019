import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Slide1,
  Slide10,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
  Slide9,
} from './styles';

import coiBlackTrack from '~/assets/tv/coi-black-track.svg';
import blackNovember from '~/assets/tv/black-november.svg';
import conditions from '~/assets/tv/conditions.svg';
import coiBlackDays from '~/assets/tv/coi-black-days.svg';
import coiBlackCircle from '~/assets/tv/coi-black-circle.svg';
import coiBlackBalls from '~/assets/tv/coi-black-balls.svg';
import botox from '~/assets/tv/botox.svg';
import coiBlackWoman from '~/assets/tv/coi-black-woman.svg';
import clareamento from '~/assets/tv/clareamento.svg';
import coiBlackMan from '~/assets/tv/coi-black-man.png';
import lentesContato from '~/assets/tv/lentes-contato.svg';
import coiBlackWoman2 from '~/assets/tv/coi-black-woman-2.svg';
import coiBlack from '~/assets/tv/coi-black.svg';
import transformHour from '~/assets/tv/transform-hour.svg';
import manSmiles from '~/assets/tv/man-smiles.png';
import cuidarDeVoce from '~/assets/tv/cuidar-de-voce.svg';
import beforeAfter1 from '~/assets/tv/before-after-1.svg';
import beforeAfter2 from '~/assets/tv/before-after-2.svg';
import yourHour from '~/assets/tv/your-hour.svg';

interface ICoiBlack {
  show: boolean;
  startAnim: boolean;
}

const CoiBlack: React.FC<ICoiBlack> = ({ show, startAnim }) => {
  const [step, setStep] = useState(0);

  const changeSlide = useCallback((stepSelected) => {
    setTimeout(() => {
      setStep(stepSelected);
      if (stepSelected + 1 <= 10) {
        changeSlide(stepSelected + 1);
      } else {
        changeSlide(1);
      }
    }, 10000);
  }, []);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setTimeout(() => {
        changeSlide(1);
      }, 300);
    }
  }, [changeSlide, startAnim]);

  useEffect(() => {
    if (startAnim) {
      setStep(1);
      setTimeout(() => {
        changeSlide(1);
      }, 300);
    }
  }, [changeSlide, startAnim]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1 show={step === 1} startAnim={step === 1} notShow={step !== 0}>
        <div className="slide-container">
          <img src={coiBlackTrack} alt="COI Black" className="track" />
          <img
            src={blackNovember}
            alt="Black November"
            className="black-november"
          />
          <img src={coiBlackTrack} alt="COI Black" className="track-inverse" />
        </div>
      </Slide1>
      <Slide2 show={step === 2} startAnim={step === 2} notShow={step !== 0}>
        <div className="slide-container">
          <img src={coiBlackTrack} alt="COI Black" className="track" />
          <img
            src={conditions}
            alt="Condições imperdíveis"
            className="conditions"
          />
          <img src={coiBlackTrack} alt="COI Black" className="track-inverse" />
        </div>
      </Slide2>
      <Slide3
        show={step === 3}
        startAnim={step === 3}
        notShow={step !== 0}
        hide={step < 3 || step > 5}
      >
        <div className="slide-container">
          <img src={coiBlackDays} alt="" className="days" />
          <img src={coiBlackBalls} alt="" className="balls" />
          <div className="ball" />
          <img src={botox} alt="" className="text" />
          <img src={coiBlackWoman} alt="" className="woman" />
        </div>
      </Slide3>
      <Slide4 show={step === 4} startAnim={step === 4} notShow={step !== 0}>
        <div className="slide-container">
          <div className="ball" />
          <img src={clareamento} alt="" className="text" />
          <img src={coiBlackMan} alt="" className="man" />
        </div>
      </Slide4>
      <Slide5
        show={step === 5}
        startAnim={step === 5}
        notShow={step !== 0}
        hide={step < 3 || step > 5}
      >
        <div className="slide-container">
          <div className="ball" />
          <img src={lentesContato} alt="" className="text" />
          <img src={coiBlackWoman2} alt="" className="woman" />
          <img src={coiBlackCircle} alt="" className="circle" />
        </div>
      </Slide5>
      <Slide6 show={step === 6} startAnim={step === 6} notShow={step !== 0}>
        <div className="slide-container">
          <img src={coiBlack} alt="" className="black" />
          <img src={transformHour} alt="" className="hour" />
          <img src={manSmiles} alt="" className="smile" />
        </div>
      </Slide6>
      <Slide7 show={step === 7} startAnim={step === 7} notShow={step !== 0}>
        <div className="slide-container">
          <img src={cuidarDeVoce} alt="" className="help-you" />
        </div>
      </Slide7>
      <Slide8 show={step === 8} startAnim={step === 8} notShow={step !== 0}>
        <div className="slide-container">
          <img src={beforeAfter1} alt="" className="before-after" />
        </div>
      </Slide8>
      <Slide9 show={step === 9} startAnim={step === 9} notShow={step !== 0}>
        <div className="slide-container">
          <img src={beforeAfter2} alt="" className="before-after" />
        </div>
      </Slide9>
      <Slide10 show={step === 10} startAnim={step === 10} notShow={step !== 0}>
        <div className="slide-container">
          <img src={yourHour} alt="" className="your-hour" />
        </div>
      </Slide10>
    </Container>
  );
};

export default CoiBlack;
