import styled from 'styled-components';

import bg from '~/assets/tv/bg-coi-black.svg';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
  hide?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-image: url(${bg});
  background-position: -1px -1px;
  background-size: contain;
  background-color: #202020;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .track {
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: ${(props) => (props.show ? 1 : 0)};
      left: ${(props) => (props.startAnim ? -1405 : 0)}px;
      transition: left 40s, opacity 0.3s;
    }

    .black-november {
      width: 665px;
      height: 186.5px;
      position: absolute;
      left: 155px;
      top: 182px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
      transform: scale(${(props) => (props.startAnim ? 1 : 2)});
    }

    .track-inverse {
      position: absolute;
      bottom: 0;
      right: ${(props) => (props.startAnim ? -1405 : 0)}px;
      transition: right 40s, opacity 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: #f6c154;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition-duration: 0.3s;

    .track {
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: ${(props) => (props.show ? 1 : 0)};
      left: ${(props) => (props.startAnim ? -1405 : 0)}px;
      transition: left 40s, opacity 0.3s;
    }

    .conditions {
      width: 589px;
      height: 287.5px;
      position: absolute;
      left: 190px;
      top: 155px;
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }

    .track-inverse {
      position: absolute;
      bottom: 0;
      right: ${(props) => (props.startAnim ? -1405 : 0)}px;
      transition: right 40s, opacity 0.3s;
      opacity: ${(props) => (props.show ? 1 : 0)};
    }
  }
`;

export const Slide3 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .days {
      width: 331px;
      height: 189.5px;
      position: absolute;
      left: 63px;
      top: 75px;
      opacity: ${(props) => (!props.hide ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .balls {
      width: 138px;
      height: 214px;
      position: absolute;
      left: 42px;
      top: 336px;
      opacity: ${(props) => (!props.hide ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .ball {
      width: 17.37px;
      height: 17.37px;
      position: absolute;
      left: 102px;
      top: 393px;
      background-color: #f6c154;
      border-radius: 50%;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .text {
      width: 177.5px;
      height: 75.5px;
      position: absolute;
      left: 172px;
      top: 391px;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .woman {
      width: 378px;
      height: 402.5px;
      position: absolute;
      left: 551px;
      top: 88px;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }
  }
`;

export const Slide4 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .ball {
      width: 17.37px;
      height: 17.37px;
      position: absolute;
      left: 102px;
      top: 422px;
      background-color: #f6c154;
      border-radius: 50%;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .text {
      width: 338px;
      height: 68px;
      position: absolute;
      left: 172px;
      top: 391px;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .man {
      width: 466px;
      height: 485px;
      position: absolute;
      left: 514px;
      top: 57px;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }
  }
`;

export const Slide5 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .circle {
      width: 117.5px;
      height: 117.5px;
      position: absolute;
      left: 826px;
      top: 50px;
      transform: rotateZ(${(props) => (!props.hide ? -720 : 0)}deg);
      opacity: ${(props) => (!props.hide ? 1 : 0)};
      transition: transform 40s, opacity 0.3s;
    }

    .ball {
      width: 17.37px;
      height: 17.37px;
      position: absolute;
      left: 102px;
      top: 452px;
      background-color: #f6c154;
      border-radius: 50%;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .text {
      width: 289px;
      height: 90.5px;
      position: absolute;
      left: 172px;
      top: 389px;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }

    .woman {
      width: 439px;
      height: 485px;
      position: absolute;
      left: 505px;
      top: 57px;
      opacity: ${(props) => (props.startAnim ? 1 : 0)};
      transition-duration: 0.3s;
    }
  }
`;

export const Slide6 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.startAnim ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .black {
      width: 203px;
      height: 38.5px;
      position: absolute;
      left: 88px;
      top: 99.75px;
    }

    .hour {
      width: 298px;
      height: 57.5px;
      position: absolute;
      left: 583px;
      top: 88.75px;
    }

    .smile {
      width: 815px;
      height: 271px;
      position: absolute;
      left: 77px;
      top: 192.75px;
    }
  }
`;

export const Slide7 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.startAnim ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .help-you {
      width: 980px;
      height: 551.25px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
`;

export const Slide8 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.startAnim ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .before-after {
      width: 821px;
      height: 437px;
      position: absolute;
      left: 93px;
      top: 50.75px;
    }
  }
`;

export const Slide9 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.startAnim ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .before-after {
      width: 780px;
      height: 444px;
      position: absolute;
      left: 76px;
      top: 75.75px;
    }
  }
`;

export const Slide10 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.startAnim ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .your-hour {
      width: 980px;
      height: 551.25px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
`;
