/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// import { BsWhatsapp } from 'react-icons/bs';

// import { ImPlus } from 'react-icons/im';
import {
  Container,
  // SlideMission,
  // SlideOurStructure,
  // SlideTriviaIntro,
  // SlideTriviaAutoclave,
  // SlideCoiKidsIntro,
  // SlideVision,
  // SlideValues,
  // SlideFantasticWorld,
  // SlideTriviaDistilledWater,
  // SlideTriviaAir,
  // SlideTriviaXRay,
  // SlideCoiIndicates,
  // SlideStayInformed,
  // ValentinesVideo,
  // ValentinesDay,
  // PregnancyCheckUp,
  // TransparentAligners,
  // Specialties,
  // FathersDay,
} from './styles';

// import SlideFantasticWorldTimeline from './SlideFantasticWorldTimeline';
// import SlideCoiKidsInvisalign from './SlideCoiKidsInvisalign';

// import logo from '~/assets/tv/logo.svg';
// import shortLogo from '~/assets/tv/short-logo.svg';
// import details from '~/assets/tv/details.svg';
// import woman from '~/assets/tv/woman.png';
// import man from '~/assets/tv/man.png';
// import details2 from '~/assets/tv/details-2.svg';
// import man2 from '~/assets/tv/man-2.png';
// import details3 from '~/assets/tv/details-3.svg';
// // import bgOurStructure from '~/assets/tv/bg-our-structure.png';
// import logoWhite from '~/assets/tv/white-logo.svg';
// import garage from '~/assets/tv/garage.svg';
// import wheelchair from '~/assets/tv/wheelchair.svg';
// import elevator from '~/assets/tv/elevator.svg';
// import kids from '~/assets/tv/kids.svg';
// import downDetail from '~/assets/tv/down-detail.svg';
// import upDetail from '~/assets/tv/up-detail.svg';
// import bigWhiteLogo from '~/assets/tv/big-white-logo.svg';
// import autoclave from '~/assets/tv/autoclave.png';
// import logoKids from '~/assets/tv/logo-kids.svg';
// import child1 from '~/assets/tv/child-1.png';
// import child2 from '~/assets/tv/child-2.png';
// import child3 from '~/assets/tv/child-3.png';
// import child4 from '~/assets/tv/child-4.png';
// import child5 from '~/assets/tv/child-5.png';
// import roomKids from '~/assets/tv/room-kids.png';
// import coikidsLogo from '~/assets/tv/coikids-logo.svg';
// import distilledWater from '~/assets/tv/distilled-water.png';
// // import bgOurStructure2 from '~/assets/tv/bg-our-structure-2.png';
// import air from '~/assets/tv/air.png';
// // import bgOurStructure3 from '~/assets/tv/bg-our-structure-3.png';
// import xRay from '~/assets/tv/x-ray.png';
// import coiIndicates from '~/assets/tv/coi-indicates.png';
// import devsigner from '~/assets/tv/devsigner-logo.svg';
// import bgNews from '~/assets/tv/bg-news.png';
// import kidsDetail from '~/assets/tv/kids-detail.png';
// import triviaDetail from '~/assets/tv/trivia-detail.svg';
// import darkLogo from '~/assets/tv/dark-logo.svg';
// import valentines from '~/assets/tv/valentines.png';
// import qrCodeValentines from '~/assets/tv/qr-code-valentines.svg';
// import bgValentines from '~/assets/tv/bg-valentines.svg';
// import pregnancyIcon from '~/assets/tv/pregnancy-icon.svg';
// import pregnancy from '~/assets/tv/pregnancy.png';
// import detailsClearCorrect from '~/assets/tv/details-clear-correct.svg';
// import invisalignAligner from '~/assets/tv/invisalign-aligner.png';
// import front from '~/assets/tv/front.png';
// import whiteShortLogo from '~/assets/tv/white-short-logo.svg';
// import qrCodeWhatsapp from '~/assets/tv/qrcode-whatsapp.svg';
// import bgFathersDay from '~/assets/tv/bg-fathers-day.png';
// import dadAndSon from '~/assets/tv/dad-and-son.png';
// import circles from '~/assets/tv/circles.png';
// import fullLogo from '~/assets/tv/white-full-logo.png';

// import PreventiveCheckUp from './PreventiveCheckUp';
// import CoiExperience from './CoiExperience';
// import Plenum from './Plenum';
// import Holidays from './Holidays';
// import Botox from './Botox';
// import ClearDay from './ClearDay';
import CoiBlack from './CoiBlack';

interface ISlide {
  slide: string;
  slideTime: number;
  beforeTime?: number;
  afterTime?: number;
  bgSlide?: string;
}

const Tv: React.FC = () => {
  const valentinesVideoRef = useRef<HTMLVideoElement>(null);
  const [slideSelected, setSlideSelected] = useState({} as ISlide);
  // const [showBefore, setShowBefore] = useState('SlideCoiKidsIntro');
  // const [animSlideSelected, setAnimSlideSelected] = useState('');
  // const [endAnimSlideSelected, setEndAnimSlideSelected] = useState('');

  const slides = useMemo<ISlide[]>(
    () => [
      { slide: 'CoiBlack', slideTime: 10000000 },
      // { slide: 'SlideMission', slideTime: 10000 },
      // { slide: 'SlideOurStructure', slideTime: 25000, bgSlide: bgOurStructure },
      // {
      //   slide: 'PreventiveCheckUp',
      //   slideTime: 50000,
      //   bgSlide: bgOurStructure,
      // },
      // {
      //   slide: 'SlideTriviaIntro',
      //   slideTime: 3000,
      //   beforeTime: 300,
      //   afterTime: 300,
      // },
      // { slide: 'SlideTriviaAutoclave', slideTime: 14000 },
      // {
      //   slide: 'SlideCoiKidsIntro',
      //   slideTime: 3600,
      //   beforeTime: 1200,
      //   afterTime: 1400,
      // },
      // { slide: 'SlideFantasticWorld', slideTime: 11000 },
      // {
      //   slide: 'SlideFantasticWorldTimeline',
      //   slideTime: 20000,
      //   beforeTime: 1200,
      // },
      // {
      //   slide: 'SlideTriviaIntro',
      //   slideTime: 3000,
      //   beforeTime: 300,
      //   afterTime: 300,
      // },
      // {
      //   slide: 'SlideTriviaDistilledWater',
      //   slideTime: 6000,
      // },
      // { slide: 'SlideVision', slideTime: 9000 },
      // {
      //   slide: 'SlideOurStructure',
      //   slideTime: 25000,
      //   bgSlide: bgOurStructure2,
      // },
      // {
      //   slide: 'CoiExperience',
      //   slideTime: 30000,
      // },
      // {
      //   slide: 'SlideTriviaIntro',
      //   slideTime: 3000,
      //   beforeTime: 300,
      //   afterTime: 300,
      // },
      // {
      //   slide: 'SlideTriviaAir',
      //   slideTime: 10000,
      // },
      // { slide: 'SlideValues', slideTime: 25000 },
      // {
      //   slide: 'SlideOurStructure',
      //   slideTime: 25000,
      //   bgSlide: bgOurStructure3,
      // },
      // {
      //   slide: 'PregnancyCheckUp',
      //   slideTime: 8000,
      // },
      // { slide: 'Botox', slideTime: 25000 },
      // {
      //   slide: 'TransparentAligners',
      //   slideTime: 10000,
      // },
      // {
      //   slide: 'Plenum',
      //   slideTime: 61000,
      // },
      // { slide: 'Specialties', slideTime: 7500 },
      // {
      //   slide: 'SlideTriviaIntro',
      //   slideTime: 3000,
      //   beforeTime: 300,
      //   afterTime: 300,
      // },
      // {
      //   slide: 'SlideTriviaXRay',
      //   slideTime: 13000,
      //   afterTime: 1200,
      // },
      // { slide: 'SlideStayInformed', slideTime: 15000 },
    ],
    []
  );

  const changeSlide = useCallback(
    (index) => {
      const { slide, slideTime } = slides[index];

      if (slides[index + 1] && slides[index + 1].beforeTime) {
        const nextSlide = slides[index + 1];
        if (nextSlide.beforeTime)
          setTimeout(() => {
            // setShowBefore(nextSlide.slide);
            // setAnimSlideSelected(nextSlide.slide);
          }, slideTime - nextSlide.beforeTime);
      }

      if (slides[index - 1] && slides[index - 1].afterTime) {
        const prevSlide = slides[index - 1];
        if (prevSlide.afterTime) {
          // setEndAnimSlideSelected(prevSlide.slide);
          setSlideSelected(slides[index]);
          setTimeout(() => {
            // setShowBefore('');
            // setEndAnimSlideSelected('');
          }, prevSlide.afterTime);
        }
      } else {
        setSlideSelected(slides[index]);
      }

      setTimeout(() => {
        // setAnimSlideSelected(slide);
        if (index + 1 < slides.length) {
          setTimeout(() => {
            changeSlide(index + 1);
          }, slideTime);
        } else {
          setTimeout(() => {
            changeSlide(0);
          }, slideTime);
        }
      }, 100);
    },
    [slides]
  );

  useEffect(() => {
    if (slideSelected.slide === 'ValentinesVideo') {
      valentinesVideoRef.current?.play();
    }
  }, [slideSelected.slide]);

  useEffect(() => {
    changeSlide(0);

    // setSlideSelected({
    //   slide: 'SlideTriviaXRay',
    //   slideTime: 3000,
    // });
    // setAnimSlideSelected('SlideTriviaXRay');
    // setTimeout(() => {
    //   setEndAnimSlideSelected('SlideCoiIndicates');
    //   setSlideSelected({
    //     slide: 'SlideStayInformed',
    //     slideTime: 3000,
    //   });
    //   setAnimSlideSelected('SlideStayInformed');
    // }, 5000);
  }, [changeSlide]);

  return (
    <Container className="position-relative">
      <CoiBlack
        show={slideSelected.slide === 'CoiBlack'}
        startAnim={slideSelected.slide === 'CoiBlack'}
      />
      {/* <FathersDay
        show={slideSelected.slide === 'FathersDay'}
        startAnim={slideSelected.slide === 'FathersDay'}
        bgImage={bgFathersDay}
      >
        <div className="slide-container d-flex position-relative">
          <img src={fullLogo} alt="logo" className="logo" />
          <p className="title">
            <b>Semana dos pais</b> na COI Prime
          </p>
          <p className="content">
            Estamos presenteando seu pai com uma <b>consulta de cortesia</b>,
            saiba mais na recepção.
          </p>
          <img src={circles} alt="círculos" className="circles" />
          <img src={dadAndSon} alt="Pai e filho" className="dadAndSon" />
        </div>
      </FathersDay>
      <Specialties show={slideSelected.slide === 'Specialties'} bgImage={front}>
        <div className="slide-container d-flex position-relative">
          <img src={whiteShortLogo} alt="logo" className="logo" />
          <div>
            <p className="content">
              Aqui na COI Prime temos{' '}
              <b>todas as especialidades da ortodontia</b>
            </p>
          </div>
        </div>
      </Specialties>
      <Botox
        show={slideSelected.slide === 'Botox'}
        startAnim={animSlideSelected === 'Botox'}
      />
      <Plenum
        show={slideSelected.slide === 'Plenum'}
        startAnim={animSlideSelected === 'Plenum'}
      />
      <ClearDay
        show={slideSelected.slide === 'ClearDay'}
        startAnim={animSlideSelected === 'ClearDay'}
      />
      <TransparentAligners
        show={slideSelected.slide === 'TransparentAligners'}
        startAnim={animSlideSelected === 'TransparentAligners'}
      >
        <div className="slide-container d-flex position-relative">
          <div className="box-content">
            <p className="title fw-light mb-0">Alinhadores Transparentes</p>
            <div className="d-flex align-items-center content content-1">
              <div>
                <ImPlus size={22} color="#63BCBA" />
              </div>
              <p className="rounded-pill px-4 py-2 mb-0">Mais conforto</p>
            </div>
            <div className="d-flex align-items-center content content-2">
              <div>
                <ImPlus size={22} color="#63BCBA" />
              </div>
              <p className="rounded-pill px-4 py-2 mb-0">Mais eficiente</p>
            </div>
            <div className="d-flex align-items-center content content-3">
              <div>
                <ImPlus size={22} color="#63BCBA" />
              </div>
              <p className="rounded-pill px-4 py-2 mb-0">Mais rápido</p>
            </div>
          </div>
          <img src={detailsClearCorrect} alt="details" className="details" />
          <img src={invisalignAligner} alt="aligner" className="aligner" />
        </div>
      </TransparentAligners>
      <PregnancyCheckUp
        show={slideSelected.slide === 'PregnancyCheckUp'}
        startAnim={animSlideSelected === 'PregnancyCheckUp'}
      >
        <div className="slide-container d-flex position-relative">
          <img src={pregnancyIcon} alt="pregnancy-icon" className="icon" />
          <div className="box-content">
            <p className="title fw-light mb-0">Check-up Gestante</p>
            <p className="content">
              Todo cuidado odontológico para sua gestação
            </p>
          </div>
          <img src={shortLogo} alt="shortLogo" className="logo" />
          <img src={pregnancy} alt="woman" className="woman" />
        </div>
      </PregnancyCheckUp>
      <PreventiveCheckUp
        show={slideSelected.slide === 'PreventiveCheckUp'}
        startAnim={animSlideSelected === 'PreventiveCheckUp'}
      />
      <CoiExperience
        show={slideSelected.slide === 'CoiExperience'}
        startAnim={animSlideSelected === 'CoiExperience'}
      />
      <SlideMission
        show={slideSelected.slide === 'SlideMission'}
        startAnim={animSlideSelected === 'SlideMission'}
      >
        <div className="slide-container d-flex position-relative">
          <div className="title">
            <p className="fw-light mb-0">NOSSA</p>
            <p className="fw-medium">Missão</p>
          </div>
          <p className="content">
            Proporcionar aos nossos pacientes o acesso ao melhor da odontologia
            moderna com eficiência, qualidade e conforto.
          </p>
          <img src={logo} alt="logo" className="logo" />
          <img src={details} alt="details" className="details" />
          <img src={woman} alt="woman" className="woman" />
        </div>
      </SlideMission>
      <SlideTriviaAutoclave
        show={slideSelected.slide === 'SlideTriviaAutoclave'}
        startAnim={animSlideSelected === 'SlideTriviaAutoclave'}
      >
        <div className="slide-container">
          <img src={bigWhiteLogo} alt="logo" className="logo" />
          <p className="title">Você sabia?</p>
          <p className="content">
            Que aqui na COI Prime, a <b>Autoclave</b> é responsável pela
            esterilização dos nossos instrumentos
          </p>
          <p className="p-btn">a Nível Hospitalar</p>
          <img src={triviaDetail} alt="triviaDetail" className="detail" />
          <img src={autoclave} alt="autoclave" className="autoclave" />
        </div>
      </SlideTriviaAutoclave>
      <SlideFantasticWorld
        show={slideSelected.slide === 'SlideFantasticWorld'}
        startAnim={animSlideSelected === 'SlideFantasticWorld'}
        bgImage={roomKids}
      >
        <div className="slide-container">
          <p className="title">Fantástico mundo</p>
          <img src={coikidsLogo} alt="coikidsLogo" className="logo" />
          <p className="content">
            Onde cuidamos dos pequenos sorrisos com alegria e carinho, tornando
            a odontologia uma experiência divertida!
          </p>
        </div>
      </SlideFantasticWorld>
      <SlideFantasticWorldTimeline
        show={
          showBefore === 'SlideFantasticWorldTimeline' ||
          slideSelected.slide === 'SlideFantasticWorldTimeline'
        }
        startAnim={animSlideSelected === 'SlideFantasticWorldTimeline'}
      />
      <Holidays
        show={slideSelected.slide === 'Holidays'}
        startAnim={animSlideSelected === 'Holidays'}
      />
      <SlideTriviaDistilledWater
        show={slideSelected.slide === 'SlideTriviaDistilledWater'}
        startAnim={animSlideSelected === 'SlideTriviaDistilledWater'}
      >
        <div className="slide-container">
          <img src={bigWhiteLogo} alt="logo" className="logo" />
          <p className="title">Você sabia?</p>
          <p className="content">
            Que em nossos tratamentos a água é destilada?
          </p>
          <img src={triviaDetail} alt="triviaDetail" className="detail" />
          <img
            src={distilledWater}
            alt="distilled water"
            className="distilled-water"
          />
        </div>
      </SlideTriviaDistilledWater>
      <SlideVision
        show={slideSelected.slide === 'SlideVision'}
        startAnim={animSlideSelected === 'SlideVision'}
      >
        <div className="slide-container d-flex position-relative">
          <div className="title">
            <p className="fw-light mb-0">Nossa</p>
            <p className="fw-medium">Visão</p>
          </div>
          <p className="content">
            Tornar-se referência em saúde e estética bucal, com excelência e
            alta tecnologia na odontologia.
          </p>
          <img src={logo} alt="logo" className="logo" />
          <img src={details2} alt="details" className="details" />
          <img src={man} alt="woman" className="woman" />
        </div>
      </SlideVision>
      <SlideTriviaAir
        show={slideSelected.slide === 'SlideTriviaAir'}
        startAnim={animSlideSelected === 'SlideTriviaAir'}
      >
        <div className="slide-container">
          <img src={bigWhiteLogo} alt="logo" className="logo" />
          <p className="title">Você sabia?</p>
          <p className="content">
            Que o ar que alimenta os nossos equipamentos passa por um sistema de
            filtragem para chegar estéril na sua boca?
          </p>
          <img src={triviaDetail} alt="triviaDetail" className="detail" />
          <img src={air} alt="air" className="air" />
        </div>
      </SlideTriviaAir>
      <SlideCoiKidsInvisalign
        show={slideSelected.slide === 'SlideCoiKidsInvisalign'}
        startAnim={animSlideSelected === 'SlideCoiKidsInvisalign'}
      />
      <SlideValues
        show={slideSelected.slide === 'SlideValues'}
        startAnim={animSlideSelected === 'SlideValues'}
      >
        <div className="slide-container d-flex position-relative">
          <div className="title">
            <p className="fw-light mb-0">NOSSOS</p>
            <p className="fw-medium">Valores</p>
          </div>
          <div className="content">
            <div>
              <p>
                <span className="fw-semibold">Respeito</span> - com nossos
                pacientes, colaboradores e parceiros;
              </p>
            </div>
            <div>
              <p>
                <span className="fw-semibold">Qualidade</span> - Em todo
                processo do atendimento ao paciente, na execução dos tratamentos
                e materiais;
              </p>
            </div>
            <div>
              <p>
                <span className="fw-semibold">Ética</span> - Exercer a
                odontologia com excelência, seguindo e respeitando as normas e
                protocolos das autarquias CFO e CROSP.
              </p>
            </div>
          </div>
          <img src={logo} alt="logo" className="logo" />
          <img src={details3} alt="details" className="details" />
          <img src={man2} alt="woman" className="woman" />
        </div>
      </SlideValues>
      <SlideStayInformed
        show={slideSelected.slide === 'SlideStayInformed'}
        startAnim={animSlideSelected === 'SlideStayInformed'}
        endAnim={endAnimSlideSelected === 'SlideStayInformed'}
        bgImage={bgNews}
      >
        <div className="slide-container">
          <p className="title">
            Quer acompanhar todas as novidades e promoções?
          </p>
          <img
            src={qrCodeWhatsapp}
            alt="qrCodeWhatsapp"
            className="qr-whatsapp"
          />
          <p className="content">
            <b>Salve nosso contato</b> e fique por dentro de todas as novidades
          </p>
          <div className="d-flex align-items-center devsigner">
            <p className="mb-0">Desenvolvido por:</p>
            <img src={devsigner} alt="devsigner" />
          </div>
        </div>
      </SlideStayInformed>
      <SlideCoiIndicates
        show={
          slideSelected.slide === 'SlideCoiIndicates' ||
          endAnimSlideSelected === 'SlideCoiIndicates'
        }
        endAnim={endAnimSlideSelected === 'SlideCoiIndicates'}
        bgImage={coiIndicates}
      >
        <div className="slide-container">
          <p className="title">COI Indica</p>
          <p className="content">Ganhe por suas indicações</p>
          <p className="cta">Saiba mais na recepção</p>
        </div>
      </SlideCoiIndicates>
      <SlideTriviaXRay
        show={
          slideSelected.slide === 'SlideTriviaXRay' ||
          endAnimSlideSelected === 'SlideTriviaXRay'
        }
        startAnim={animSlideSelected === 'SlideTriviaXRay'}
        endAnim={endAnimSlideSelected === 'SlideTriviaXRay'}
      >
        <div className="slide-container">
          <div>
            <img src={bigWhiteLogo} alt="logo" className="logo" />
            <p className="title">Você sabia?</p>
            <p className="content">
              Que aqui na COI Prime, você pode realizar o exame panorâmico, sem
              precisar sair da unidade. Tudo isso para sua{' '}
              <b>maior comodidade</b>
            </p>
          </div>
          <div>
            <img src={triviaDetail} alt="triviaDetail" className="detail" />
            <img src={xRay} alt="x-ray" className="x-ray" />
          </div>
        </div>
      </SlideTriviaXRay>
      <ValentinesVideo
        show={slideSelected.slide === 'ValentinesVideo'}
        startAnim={animSlideSelected === 'ValentinesVideo'}
      >
        <div className="slide-container d-flex position-relative">
          <video
            ref={valentinesVideoRef}
            controls={false}
            playsInline
            autoPlay
            muted
            className="w-100 mb-5"
          >
            <source
              src="https://cdn.coiprime.com/valentines-video.mp4"
              type="video/mp4"
            />
            <track default kind="captions" srcLang="en" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </ValentinesVideo>
      <ValentinesDay
        show={slideSelected.slide === 'ValentinesDay'}
        startAnim={animSlideSelected === 'ValentinesDay'}
        bgImage={bgValentines}
      >
        <div className="slide-container d-flex position-relative">
          <div className="title">
            <p className="mb-0">Dia dos namorados</p>
            <p className="">aqui na COI Prime</p>
          </div>
          <img src={qrCodeValentines} alt="qrCode" className="qr-code" />
          <p className="content">
            <BsWhatsapp size={20} color="#4A3000" className="me-2" /> Confira
            mais informações
          </p>
          <img src={darkLogo} alt="logo" className="logo" />
          <img src={valentines} alt="logo" className="valentines" />
        </div>
      </ValentinesDay>
      <SlideOurStructure
        show={slideSelected.slide === 'SlideOurStructure'}
        startAnim={animSlideSelected === 'SlideOurStructure'}
        bgImage={slideSelected.bgSlide}
      >
        <div className="slide-container d-flex position-relative">
          <div className="title">
            <p className="fw-light mb-0">NOSSA</p>
            <p className="fw-medium">Estrutura</p>
          </div>
          <p className="content">
            Estrutura de 650m² dedicados à todas as especialidades da
            odontologia, com 8 consultórios, 4 salas de esperas, espaço Kids,
            elevador com acesso em toda clínica, plataforma para acessibilidade
            e estacionamento no subsolo.
          </p>
          <img src={logoWhite} alt="logo" className="logo" />
          <img src={garage} alt="garage" className="garage" />
          <img src={wheelchair} alt="wheelchair" className="wheelchair" />
          <img src={elevator} alt="elevator" className="elevator" />
          <img src={kids} alt="kids" className="kids" />
        </div>
      </SlideOurStructure>
      <SlideTriviaIntro
        show={showBefore === 'SlideTriviaIntro'}
        startAnim={animSlideSelected === 'SlideTriviaIntro'}
        endAnim={endAnimSlideSelected === 'SlideTriviaIntro'}
      >
        <div className="overflow-circle">
          <div className="slide-container d-flex position-relative">
            <p className="title fw-medium mb-0">Curiosidades</p>
            <img src={bigWhiteLogo} alt="logo" className="logo" />
            <img src={downDetail} alt="downDetail" className="down-detail" />
            <img src={upDetail} alt="upDetail" className="up-detail" />
          </div>
        </div>
      </SlideTriviaIntro>
      <SlideCoiKidsIntro
        show={showBefore === 'SlideCoiKidsIntro'}
        startAnim={animSlideSelected === 'SlideCoiKidsIntro'}
        endAnim={endAnimSlideSelected === 'SlideCoiKidsIntro'}
        bgImage={kidsDetail}
      >
        <div className="slide-container">
          <img src={child1} alt="child1" className="child child-1" />
          <img src={child2} alt="child2" className="child child-2" />
          <img src={child3} alt="child3" className="child child-3" />
          <img src={child4} alt="child4" className="child child-4" />
          <img src={child5} alt="child5" className="child child-5" />
          <div className="title">
            <img src={bigWhiteLogo} alt="bigWhiteLogo" className="logo" />
            <img src={logoKids} alt="logoKids" className="kids" />
          </div>
        </div>
      </SlideCoiKidsIntro> */}
    </Container>
  );
};

export default Tv;
